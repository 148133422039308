@import "src/assets/styles/common";

.key-feature {
  @include flex(center);
  flex-direction: column;
  gap: 8px;


  --feat-width: 250px;
  --feat-image-scale: 0.8;
  width: var(--feat-width);

  color: $blue-7;
  text-align: center;

  @include tablet-landscape {
    --feat-width: 200px;
  }
}

.key-feature-image-wrapper {
  width: 100%;
  margin-bottom: 18px;
}

.key-feat-image {
  margin: 0 auto;

  width: calc(var(--feat-width) * var(--feat-image-scale));
  height: calc(var(--feat-width) * var(--feat-image-scale));

  object-fit: scale-down;
  object-position: center;
}
.key-feat-title {
  @include font-lato(24px, 30px, $font-bold);
  color: $blue-9;
}
.key-feat-content {
  @include font-lato(16px, 22px);

}
